.App {
 
}
.adm-input {
  font-size: 16px !important;
}
.adm-form-item-label {
  font-size: 14px !important;
}
.foot-btn {
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 22px;
  background: #3378E3;
  margin-top: 30px;
  margin-bottom: 40px;
}
.head {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-top: 20px;
    margin-left:10px;

}
.head-left-title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 28px;
}
.head-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.head-margin-right {
  margin-right: 20px;
}
.triangle {
   width: 0px;
   height: 0px;
   border-bottom: 7px solid #3378E3;
   border-left: 7px solid transparent;
   border-right:  7px solid transparent;
}

.app-title {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 25px;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 18px;
}
.form-btn {
  width: 335px;
  height: 40px;
  border-radius: 8px;
  margin: 30px 20px 20px 20px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 22px;

}
.mask-list {
  position: absolute;
  top: 70px;
  left: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  right: 20px;
  height: 200px;
  border: 1px solid #ddd;
  background-color: #FFFFFF;
  z-index: 1000;
    .mask-list-item {
      font-size: 12px;
      color: #333;
      height: 30px;
      border: 1px solid #ddd;
      line-height: 30px;
      padding-left: 20px;
      padding-right: 10px;
      border-width: 0px 0px 1px 0px;
      overflow: hidden;
      /*文本不会换行*/
      white-space: nowrap;
      /*当文本溢出包含元素时，以省略号表示超出的文本*/
      text-overflow: ellipsis;
    }
  
}

.adm-list-item-title {
  margin-bottom: 10px;
}